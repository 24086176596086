/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { VFC } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo: VFC<any> = ({ description, lang, title }) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
            siteMetadata {
                title
                    description
                   
                }
            }
        }
    `);
    /**
     *  social {
                        twitter
                    }
     */

    const metaDescription = description || site.siteMetadata.description;
    const defaultTitle = site.siteMetadata?.title;

    const meta = [
        {
            name: "description",
            content: metaDescription,
        },
        {
            property: "og:title",
            content: `Raha Faridi${title ? title === " " ? "" : ": " + title : ""}`,
        },
        {
            property: "og:description",
            content: metaDescription,
        },
        {
            property: "og:image",
            content: "https://rahafaridi.com/image.jpg"
        },
        {
            property: "twitter:image",
            content: "https://rahafaridi.com/image.jpg"
        },
        {
            property: "image",
            content: "https://rahafaridi.com/image.jpg"
        },
        {
            property: "og:type",
            content: "website",
        },
        {
            name: "twitter:card",
            content: "summary",
        },
        {
            name: "twitter:creator",
            content: `@${site.siteMetadata?.social?.twitter}`,
        },
        {
            name: "twitter:title",
            content: `Raha Faridi${title ? title === " " ? "" : ": " + title : ""}`,
        },
        {
            name: "twitter:description",
            content: metaDescription,
        },
        // {
        //     name: "theme-color",
        //     content: "", 
        // }
    ];

    console.log({ meta });

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? title === " " ? "Raha Faridi" : "Raha Faridi: %s" : undefined}
            meta={meta}
        />
    );
};

Seo.defaultProps = {
    lang: "en",
    meta: [],
    description: "",
};

export default Seo;
