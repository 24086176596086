import classNames from "classnames";
import React, { VFC } from "react";
import ProgressiveImage from "react-progressive-image";
import ListCardText from "../ListCardText";

interface MusicCardProps {
    className?: string;
    name: string;
    img: string;
    thumbnail: string;
    year: string | number;
}

const MusicCard: VFC<MusicCardProps> = ({ className, name, img, thumbnail, year }) => {
    const classes = classNames({
        "music-card list-card": true
    });

    return (
        <ProgressiveImage
            src={img}
            // placeholder={thumbnail}
        >
            {(src: string, loading: boolean) => {
                return (<div className={`${classes} ${className}`}>
                    <div className="px-2">
                        <img
                            src={src}
                            alt={name}
                            loading="lazy"
                            className={"filter transition-all duration-500 object-center transform-gpu rounded shadow-lg w-192 md:w-full " + (loading ? "opacity-5" : "opacity-100")}
                        />
                        <ListCardText className={loading? "opacity-0" : "opacity-100 image-ready"}>
                            {year}
                        </ListCardText>
                    </div>
                </div>
                );
            }}
        </ProgressiveImage>
    );
};

export default MusicCard;
