import React, { VFC } from "react";
import { ArrowDownIcon } from "@heroicons/react/solid";

const ScrollToTop: VFC<{ className?: string; }> = ({ className = "" }) => {
    const goToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className={"flex justify-center cursor-pointer " + className} onClick={goToTop}>
            <div className={"transition-all transform duration-1000 rotate-180"}>
                <ArrowDownIcon
                    className={"w-6 h-6 text-white opacity-40 hover:opacity-90 "}
                />
            </div>
        </div>
    );
};

export default ScrollToTop;
