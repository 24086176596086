exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-templates-movies-list-tsx": () => import("./../../../src/templates/MoviesList.tsx" /* webpackChunkName: "component---src-templates-movies-list-tsx" */),
  "component---src-templates-music-list-tsx": () => import("./../../../src/templates/MusicList.tsx" /* webpackChunkName: "component---src-templates-music-list-tsx" */),
  "component---src-templates-radio-list-tsx": () => import("./../../../src/templates/RadioList.tsx" /* webpackChunkName: "component---src-templates-radio-list-tsx" */)
}

