/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
// import { useSpring, animated } from "react-spring";
import { Footer } from "../components";
import TopMenu from "./TopMenu";
// @ts-expect-error import error
import Logo from "./homelogo.png";
import Seo from "../parts/Seo";

function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


const Layout: React.FC<any> = ({ path, children }) => {
    const [showChild, setShowChild] = useState(false);
    const [isHomePage, setIsHomePage] = useState(path === "/");
    const [bgNumber] = useState(getRandomInt(1, 60));

    useEffect(() => {
        setIsHomePage(path === "/");
        setShowChild(true);
    }, []);

    useEffect(() => {
        setIsHomePage(path === "/");
    }, [path]);


    if (!showChild) {
        return null;
    }

    // const wrapperStyles = useSpring({
    // to: {
    //     position: "absolute",
    //     top: "3rem",
    //     left: "45%",
    //     height: "50%",
    //     width: "50%"
    // },
    // from: {
    //     position: "absolute",
    //     top: "2rem",
    //     right: "5%",
    //     height: "5%",
    //     width: "5%"
    // },
    // config: {
    //     duration: 300
    // }
    // });

    return (
        <>
            <div id="bg"></div>
            <style dangerouslySetInnerHTML={{
                __html: [
                    "body:before {",
                    "background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/bgs/raw" + (String(bgNumber).padStart(3, "0")) + ".jpg');",
                    `background-position: ${bgNumber === 20 ? "top" : "center"};`,
                    "opacity: 1",
                    "}"
                ].join("\n")
            }}>
            </style>
            <div className="m-auto w-full h-full flex flex-row-reverse items-start min-h-screen lg:p-0">
                {/* <Navigation pageName={isHomePage ? "home" : undefined} /> */}

                {isHomePage && <div className="md:m-auto w-full h-full">
                    <div className="flex flex-col-reverse h-screen justify-center lg:items-center lg:flex-row mt-4 md:mt-0 ml-2 mr-2" style={{ maxHeight: "90vh", width: "90wh" }}>
                        <Seo title=" " />
                        <TopMenu isHomePage={true} />
                        <div
                            className={"lg:w-auto animate-fadedIn flex flex-col justify-center lg:h-full"}
                        >
                            <img
                                src={Logo}
                                alt="Raha Faridi"
                                className="w-full opacity-70 pointer-events-none max-h-full"
                                loading="eager"
                            />
                        </div>
                    </div>
                </div>}


                {!isHomePage &&
                    <div className="flex flex-col w-full min-h-screen">
                        <TopMenu isHomePage={false} />
                        <main className="flex-auto flex-grow mb-2">
                            {children}
                        </main>
                        <Footer />
                    </div>}
            </div>
        </>
    );
};

export default Layout;
